export const DataLoader = {
  title: {
    loader: [
      'Mohon tunggu',
      'Memuat data',
      'Memproses data',
      'Menampilkan data',
      'Hampir siap!',
    ],
    failed: {
      text: 'Gagal menampilkan data',
      link: 'Kembali',
    },
  },
};
