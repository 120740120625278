export const DataIMT4 = {
        "male": [
          {
            "x": 0,
            "qx": 0.00524
          },
          {
            "x": 1,
            "qx": 0.00053
          },
          {
            "x": 2,
            "qx": 0.00042
          },
          {
            "x": 3,
            "qx": 0.00034
          },
          {
            "x": 4,
            "qx": 0.00029
          },
          {
            "x": 5,
            "qx": 0.00026
          },
          {
            "x": 6,
            "qx": 0.00023
          },
          {
            "x": 7,
            "qx": 0.00021
          },
          {
            "x": 8,
            "qx": 0.0002
          },
          {
            "x": 9,
            "qx": 0.0002
          },
          {
            "x": 10,
            "qx": 0.00019
          },
          {
            "x": 11,
            "qx": 0.00019
          },
          {
            "x": 12,
            "qx": 0.00019
          },
          {
            "x": 13,
            "qx": 0.0002
          },
          {
            "x": 14,
            "qx": 0.00023
          },
          {
            "x": 15,
            "qx": 0.00027
          },
          {
            "x": 16,
            "qx": 0.00031
          },
          {
            "x": 17,
            "qx": 0.00037
          },
          {
            "x": 18,
            "qx": 0.00043
          },
          {
            "x": 19,
            "qx": 0.00047
          },
          {
            "x": 20,
            "qx": 0.00049
          },
          {
            "x": 21,
            "qx": 0.00049
          },
          {
            "x": 22,
            "qx": 0.00049
          },
          {
            "x": 23,
            "qx": 0.00049
          },
          {
            "x": 24,
            "qx": 0.0005
          },
          {
            "x": 25,
            "qx": 0.00052
          },
          {
            "x": 26,
            "qx": 0.00055
          },
          {
            "x": 27,
            "qx": 0.0006
          },
          {
            "x": 28,
            "qx": 0.00065
          },
          {
            "x": 29,
            "qx": 0.0007
          },
          {
            "x": 30,
            "qx": 0.00075
          },
          {
            "x": 31,
            "qx": 0.00081
          },
          {
            "x": 32,
            "qx": 0.00087
          },
          {
            "x": 33,
            "qx": 0.00093
          },
          {
            "x": 34,
            "qx": 0.00099
          },
          {
            "x": 35,
            "qx": 0.00107
          },
          {
            "x": 36,
            "qx": 0.00116
          },
          {
            "x": 37,
            "qx": 0.00127
          },
          {
            "x": 38,
            "qx": 0.00139
          },
          {
            "x": 39,
            "qx": 0.00155
          },
          {
            "x": 40,
            "qx": 0.00173
          },
          {
            "x": 41,
            "qx": 0.00193
          },
          {
            "x": 42,
            "qx": 0.00216
          },
          {
            "x": 43,
            "qx": 0.00241
          },
          {
            "x": 44,
            "qx": 0.0027
          },
          {
            "x": 45,
            "qx": 0.00302
          },
          {
            "x": 46,
            "qx": 0.00338
          },
          {
            "x": 47,
            "qx": 0.00377
          },
          {
            "x": 48,
            "qx": 0.00418
          },
          {
            "x": 49,
            "qx": 0.00461
          },
          {
            "x": 50,
            "qx": 0.00508
          },
          {
            "x": 51,
            "qx": 0.00556
          },
          {
            "x": 52,
            "qx": 0.00609
          },
          {
            "x": 53,
            "qx": 0.00667
          },
          {
            "x": 54,
            "qx": 0.00727
          },
          {
            "x": 55,
            "qx": 0.00789
          },
          {
            "x": 56,
            "qx": 0.00847
          },
          {
            "x": 57,
            "qx": 0.00898
          },
          {
            "x": 58,
            "qx": 0.00939
          },
          {
            "x": 59,
            "qx": 0.00971
          },
          {
            "x": 60,
            "qx": 0.00999
          },
          {
            "x": 61,
            "qx": 0.01024
          },
          {
            "x": 62,
            "qx": 0.01046
          },
          {
            "x": 63,
            "qx": 0.01071
          },
          {
            "x": 64,
            "qx": 0.01104
          },
          {
            "x": 65,
            "qx": 0.01146
          },
          {
            "x": 66,
            "qx": 0.01199
          },
          {
            "x": 67,
            "qx": 0.0126
          },
          {
            "x": 68,
            "qx": 0.01329
          },
          {
            "x": 69,
            "qx": 0.01405
          },
          {
            "x": 70,
            "qx": 0.01485
          },
          {
            "x": 71,
            "qx": 0.01574
          },
          {
            "x": 72,
            "qx": 0.0167
          },
          {
            "x": 73,
            "qx": 0.01777
          },
          {
            "x": 74,
            "qx": 0.01895
          },
          {
            "x": 75,
            "qx": 0.02026
          },
          {
            "x": 76,
            "qx": 0.02369
          },
          {
            "x": 77,
            "qx": 0.02738
          },
          {
            "x": 78,
            "qx": 0.0313
          },
          {
            "x": 79,
            "qx": 0.03693
          },
          {
            "x": 80,
            "qx": 0.04518
          },
          {
            "x": 81,
            "qx": 0.05527
          },
          {
            "x": 82,
            "qx": 0.06732
          },
          {
            "x": 83,
            "qx": 0.08228
          },
          {
            "x": 84,
            "qx": 0.09478
          },
          {
            "x": 85,
            "qx": 0.10465
          },
          {
            "x": 86,
            "qx": 0.11533
          },
          {
            "x": 87,
            "qx": 0.12698
          },
          {
            "x": 88,
            "qx": 0.13947
          },
          {
            "x": 89,
            "qx": 0.15271
          },
          {
            "x": 90,
            "qx": 0.16659
          },
          {
            "x": 91,
            "qx": 0.17991
          },
          {
            "x": 92,
            "qx": 0.1939
          },
          {
            "x": 93,
            "qx": 0.20874
          },
          {
            "x": 94,
            "qx": 0.22451
          },
          {
            "x": 95,
            "qx": 0.24126
          },
          {
            "x": 96,
            "qx": 0.25715
          },
          {
            "x": 97,
            "qx": 0.27419
          },
          {
            "x": 98,
            "qx": 0.29249
          },
          {
            "x": 99,
            "qx": 0.31215
          },
          {
            "x": 100,
            "qx": 0.33331
          },
          {
            "x": 101,
            "qx": 0.35163
          },
          {
            "x": 102,
            "qx": 0.37132
          },
          {
            "x": 103,
            "qx": 0.3925
          },
          {
            "x": 104,
            "qx": 0.41527
          },
          {
            "x": 105,
            "qx": 0.43973
          },
          {
            "x": 106,
            "qx": 0.46602
          },
          {
            "x": 107,
            "qx": 0.49429
          },
          {
            "x": 108,
            "qx": 0.52467
          },
          {
            "x": 109,
            "qx": 0.55733
          },
          {
            "x": 110,
            "qx": 0.59244
          },
          {
            "x": 111,
            "qx": 1
          }
        ],
        "female": [
          {
            "x": 0,
            "qx": 0.00266
          },
          {
            "x": 1,
            "qx": 0.00041
          },
          {
            "x": 2,
            "qx": 0.00031
          },
          {
            "x": 3,
            "qx": 0.00024
          },
          {
            "x": 4,
            "qx": 0.00021
          },
          {
            "x": 5,
            "qx": 0.0002
          },
          {
            "x": 6,
            "qx": 0.00022
          },
          {
            "x": 7,
            "qx": 0.00023
          },
          {
            "x": 8,
            "qx": 0.00022
          },
          {
            "x": 9,
            "qx": 0.00021
          },
          {
            "x": 10,
            "qx": 0.00019
          },
          {
            "x": 11,
            "qx": 0.00018
          },
          {
            "x": 12,
            "qx": 0.0002
          },
          {
            "x": 13,
            "qx": 0.00022
          },
          {
            "x": 14,
            "qx": 0.00023
          },
          {
            "x": 15,
            "qx": 0.00023
          },
          {
            "x": 16,
            "qx": 0.00024
          },
          {
            "x": 17,
            "qx": 0.00024
          },
          {
            "x": 18,
            "qx": 0.00025
          },
          {
            "x": 19,
            "qx": 0.00026
          },
          {
            "x": 20,
            "qx": 0.00027
          },
          {
            "x": 21,
            "qx": 0.00028
          },
          {
            "x": 22,
            "qx": 0.0003
          },
          {
            "x": 23,
            "qx": 0.00032
          },
          {
            "x": 24,
            "qx": 0.00034
          },
          {
            "x": 25,
            "qx": 0.00038
          },
          {
            "x": 26,
            "qx": 0.00042
          },
          {
            "x": 27,
            "qx": 0.00046
          },
          {
            "x": 28,
            "qx": 0.00049
          },
          {
            "x": 29,
            "qx": 0.00052
          },
          {
            "x": 30,
            "qx": 0.00056
          },
          {
            "x": 31,
            "qx": 0.0006
          },
          {
            "x": 32,
            "qx": 0.00064
          },
          {
            "x": 33,
            "qx": 0.00069
          },
          {
            "x": 34,
            "qx": 0.00074
          },
          {
            "x": 35,
            "qx": 0.0008
          },
          {
            "x": 36,
            "qx": 0.00086
          },
          {
            "x": 37,
            "qx": 0.00093
          },
          {
            "x": 38,
            "qx": 0.001
          },
          {
            "x": 39,
            "qx": 0.00108
          },
          {
            "x": 40,
            "qx": 0.00118
          },
          {
            "x": 41,
            "qx": 0.00128
          },
          {
            "x": 42,
            "qx": 0.00141
          },
          {
            "x": 43,
            "qx": 0.00154
          },
          {
            "x": 44,
            "qx": 0.00169
          },
          {
            "x": 45,
            "qx": 0.00187
          },
          {
            "x": 46,
            "qx": 0.00209
          },
          {
            "x": 47,
            "qx": 0.0023
          },
          {
            "x": 48,
            "qx": 0.00253
          },
          {
            "x": 49,
            "qx": 0.00277
          },
          {
            "x": 50,
            "qx": 0.00305
          },
          {
            "x": 51,
            "qx": 0.00335
          },
          {
            "x": 52,
            "qx": 0.00368
          },
          {
            "x": 53,
            "qx": 0.00403
          },
          {
            "x": 54,
            "qx": 0.00442
          },
          {
            "x": 55,
            "qx": 0.00483
          },
          {
            "x": 56,
            "qx": 0.00524
          },
          {
            "x": 57,
            "qx": 0.00563
          },
          {
            "x": 58,
            "qx": 0.00601
          },
          {
            "x": 59,
            "qx": 0.00636
          },
          {
            "x": 60,
            "qx": 0.00671
          },
          {
            "x": 61,
            "qx": 0.00707
          },
          {
            "x": 62,
            "qx": 0.00746
          },
          {
            "x": 63,
            "qx": 0.00788
          },
          {
            "x": 64,
            "qx": 0.00833
          },
          {
            "x": 65,
            "qx": 0.00883
          },
          {
            "x": 66,
            "qx": 0.0094
          },
          {
            "x": 67,
            "qx": 0.01005
          },
          {
            "x": 68,
            "qx": 0.01076
          },
          {
            "x": 69,
            "qx": 0.0115
          },
          {
            "x": 70,
            "qx": 0.01229
          },
          {
            "x": 71,
            "qx": 0.01314
          },
          {
            "x": 72,
            "qx": 0.01406
          },
          {
            "x": 73,
            "qx": 0.01508
          },
          {
            "x": 74,
            "qx": 0.0162
          },
          {
            "x": 75,
            "qx": 0.01743
          },
          {
            "x": 76,
            "qx": 0.01879
          },
          {
            "x": 77,
            "qx": 0.0203
          },
          {
            "x": 78,
            "qx": 0.02326
          },
          {
            "x": 79,
            "qx": 0.0288
          },
          {
            "x": 80,
            "qx": 0.03569
          },
          {
            "x": 81,
            "qx": 0.04208
          },
          {
            "x": 82,
            "qx": 0.04907
          },
          {
            "x": 83,
            "qx": 0.0552
          },
          {
            "x": 84,
            "qx": 0.06086
          },
          {
            "x": 85,
            "qx": 0.06715
          },
          {
            "x": 86,
            "qx": 0.07318
          },
          {
            "x": 87,
            "qx": 0.08155
          },
          {
            "x": 88,
            "qx": 0.09045
          },
          {
            "x": 89,
            "qx": 0.10001
          },
          {
            "x": 90,
            "qx": 0.10913
          },
          {
            "x": 91,
            "qx": 0.11521
          },
          {
            "x": 92,
            "qx": 0.12499
          },
          {
            "x": 93,
            "qx": 0.13826
          },
          {
            "x": 94,
            "qx": 0.15451
          },
          {
            "x": 95,
            "qx": 0.17429
          },
          {
            "x": 96,
            "qx": 0.19155
          },
          {
            "x": 97,
            "qx": 0.20596
          },
          {
            "x": 98,
            "qx": 0.22227
          },
          {
            "x": 99,
            "qx": 0.23736
          },
          {
            "x": 100,
            "qx": 0.2581
          },
          {
            "x": 101,
            "qx": 0.28068
          },
          {
            "x": 102,
            "qx": 0.30562
          },
          {
            "x": 103,
            "qx": 0.33315
          },
          {
            "x": 104,
            "qx": 0.36369
          },
          {
            "x": 105,
            "qx": 0.39318
          },
          {
            "x": 106,
            "qx": 0.42883
          },
          {
            "x": 107,
            "qx": 0.46604
          },
          {
            "x": 108,
            "qx": 0.50427
          },
          {
            "x": 109,
            "qx": 0.54477
          },
          {
            "x": 110,
            "qx": 0.58702
          },
          {
            "x": 111,
            "qx": 1
          }
        ]
      }