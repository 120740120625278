export const DataSection = [
  {
    title: [
      {
        text: 'Lohh, kosong?',
      },
    ],
    content: [
      {
        paragraph: [
          [
            {
              text: 'Hmm, sepertinya halaman ini belum siap dan butuh waktu beberapa saat untuk menyelesaikannya.',
            },
          ],
        ],
      },
    ],
  },
];
