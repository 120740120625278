export const DataIMT3 = {
  male: [
    {
      x: 0,
      qx: 0.00802,
    },
    {
      x: 1,
      qx: 0.00079,
    },
    {
      x: 2,
      qx: 0.00063,
    },
    {
      x: 3,
      qx: 0.00051,
    },
    {
      x: 4,
      qx: 0.00043,
    },
    {
      x: 5,
      qx: 0.00038,
    },
    {
      x: 6,
      qx: 0.00034,
    },
    {
      x: 7,
      qx: 0.00031,
    },
    {
      x: 8,
      qx: 0.00029,
    },
    {
      x: 9,
      qx: 0.00028,
    },
    {
      x: 10,
      qx: 0.00027,
    },
    {
      x: 11,
      qx: 0.00027,
    },
    {
      x: 12,
      qx: 0.00026,
    },
    {
      x: 13,
      qx: 0.00026,
    },
    {
      x: 14,
      qx: 0.00027,
    },
    {
      x: 15,
      qx: 0.00029,
    },
    {
      x: 16,
      qx: 0.0003,
    },
    {
      x: 17,
      qx: 0.00032,
    },
    {
      x: 18,
      qx: 0.00036,
    },
    {
      x: 19,
      qx: 0.00041,
    },
    {
      x: 20,
      qx: 0.00049,
    },
    {
      x: 21,
      qx: 0.00059,
    },
    {
      x: 22,
      qx: 0.00069,
    },
    {
      x: 23,
      qx: 0.00077,
    },
    {
      x: 24,
      qx: 0.00083,
    },
    {
      x: 25,
      qx: 0.00085,
    },
    {
      x: 26,
      qx: 0.00083,
    },
    {
      x: 27,
      qx: 0.00079,
    },
    {
      x: 28,
      qx: 0.00075,
    },
    {
      x: 29,
      qx: 0.00074,
    },
    {
      x: 30,
      qx: 0.00076,
    },
    {
      x: 31,
      qx: 0.0008,
    },
    {
      x: 32,
      qx: 0.00083,
    },
    {
      x: 33,
      qx: 0.00084,
    },
    {
      x: 34,
      qx: 0.00086,
    },
    {
      x: 35,
      qx: 0.00091,
    },
    {
      x: 36,
      qx: 0.00099,
    },
    {
      x: 37,
      qx: 0.00109,
    },
    {
      x: 38,
      qx: 0.0012,
    },
    {
      x: 39,
      qx: 0.00135,
    },
    {
      x: 40,
      qx: 0.00153,
    },
    {
      x: 41,
      qx: 0.00175,
    },
    {
      x: 42,
      qx: 0.00196,
    },
    {
      x: 43,
      qx: 0.00219,
    },
    {
      x: 44,
      qx: 0.00246,
    },
    {
      x: 45,
      qx: 0.00279,
    },
    {
      x: 46,
      qx: 0.00318,
    },
    {
      x: 47,
      qx: 0.00363,
    },
    {
      x: 48,
      qx: 0.00414,
    },
    {
      x: 49,
      qx: 0.00471,
    },
    {
      x: 50,
      qx: 0.00538,
    },
    {
      x: 51,
      qx: 0.00615,
    },
    {
      x: 52,
      qx: 0.00699,
    },
    {
      x: 53,
      qx: 0.00784,
    },
    {
      x: 54,
      qx: 0.00872,
    },
    {
      x: 55,
      qx: 0.00961,
    },
    {
      x: 56,
      qx: 0.01051,
    },
    {
      x: 57,
      qx: 0.01142,
    },
    {
      x: 58,
      qx: 0.01232,
    },
    {
      x: 59,
      qx: 0.01322,
    },
    {
      x: 60,
      qx: 0.01417,
    },
    {
      x: 61,
      qx: 0.01521,
    },
    {
      x: 62,
      qx: 0.01639,
    },
    {
      x: 63,
      qx: 0.01773,
    },
    {
      x: 64,
      qx: 0.01926,
    },
    {
      x: 65,
      qx: 0.021,
    },
    {
      x: 66,
      qx: 0.02288,
    },
    {
      x: 67,
      qx: 0.02486,
    },
    {
      x: 68,
      qx: 0.02702,
    },
    {
      x: 69,
      qx: 0.02921,
    },
    {
      x: 70,
      qx: 0.03182,
    },
    {
      x: 71,
      qx: 0.03473,
    },
    {
      x: 72,
      qx: 0.03861,
    },
    {
      x: 73,
      qx: 0.04264,
    },
    {
      x: 74,
      qx: 0.04687,
    },
    {
      x: 75,
      qx: 0.05155,
    },
    {
      x: 76,
      qx: 0.05664,
    },
    {
      x: 77,
      qx: 0.06254,
    },
    {
      x: 78,
      qx: 0.06942,
    },
    {
      x: 79,
      qx: 0.07734,
    },
    {
      x: 80,
      qx: 0.08597,
    },
    {
      x: 81,
      qx: 0.09577,
    },
    {
      x: 82,
      qx: 0.10593,
    },
    {
      x: 83,
      qx: 0.11683,
    },
    {
      x: 84,
      qx: 0.12888,
    },
    {
      x: 85,
      qx: 0.14241,
    },
    {
      x: 86,
      qx: 0.15738,
    },
    {
      x: 87,
      qx: 0.17368,
    },
    {
      x: 88,
      qx: 0.1911,
    },
    {
      x: 89,
      qx: 0.20945,
    },
    {
      x: 90,
      qx: 0.22853,
    },
    {
      x: 91,
      qx: 0.24638,
    },
    {
      x: 92,
      qx: 0.26496,
    },
    {
      x: 93,
      qx: 0.2845,
    },
    {
      x: 94,
      qx: 0.30511,
    },
    {
      x: 95,
      qx: 0.32682,
    },
    {
      x: 96,
      qx: 0.34662,
    },
    {
      x: 97,
      qx: 0.3677,
    },
    {
      x: 98,
      qx: 0.39016,
    },
    {
      x: 99,
      qx: 0.41413,
    },
    {
      x: 100,
      qx: 0.43974,
    },
    {
      x: 101,
      qx: 0.45994,
    },
    {
      x: 102,
      qx: 0.48143,
    },
    {
      x: 103,
      qx: 0.50431,
    },
    {
      x: 104,
      qx: 0.52864,
    },
    {
      x: 105,
      qx: 0.5545,
    },
    {
      x: 106,
      qx: 0.58198,
    },
    {
      x: 107,
      qx: 0.61119,
    },
    {
      x: 108,
      qx: 0.64222,
    },
    {
      x: 109,
      qx: 0.67518,
    },
    {
      x: 110,
      qx: 0.71016,
    },
    {
      x: 111,
      qx: 1,
    },
  ],
  female: [
    {
      x: 0,
      qx: 0.0037,
    },
    {
      x: 1,
      qx: 0.00056,
    },
    {
      x: 2,
      qx: 0.00042,
    },
    {
      x: 3,
      qx: 0.00033,
    },
    {
      x: 4,
      qx: 0.00028,
    },
    {
      x: 5,
      qx: 0.00027,
    },
    {
      x: 6,
      qx: 0.0003,
    },
    {
      x: 7,
      qx: 0.00031,
    },
    {
      x: 8,
      qx: 0.0003,
    },
    {
      x: 9,
      qx: 0.00028,
    },
    {
      x: 10,
      qx: 0.00025,
    },
    {
      x: 11,
      qx: 0.00024,
    },
    {
      x: 12,
      qx: 0.00026,
    },
    {
      x: 13,
      qx: 0.00028,
    },
    {
      x: 14,
      qx: 0.00029,
    },
    {
      x: 15,
      qx: 0.00028,
    },
    {
      x: 16,
      qx: 0.00025,
    },
    {
      x: 17,
      qx: 0.00024,
    },
    {
      x: 18,
      qx: 0.00023,
    },
    {
      x: 19,
      qx: 0.00024,
    },
    {
      x: 20,
      qx: 0.00026,
    },
    {
      x: 21,
      qx: 0.00029,
    },
    {
      x: 22,
      qx: 0.00033,
    },
    {
      x: 23,
      qx: 0.00037,
    },
    {
      x: 24,
      qx: 0.00039,
    },
    {
      x: 25,
      qx: 0.00042,
    },
    {
      x: 26,
      qx: 0.00044,
    },
    {
      x: 27,
      qx: 0.00046,
    },
    {
      x: 28,
      qx: 0.00048,
    },
    {
      x: 29,
      qx: 0.00051,
    },
    {
      x: 30,
      qx: 0.00054,
    },
    {
      x: 31,
      qx: 0.00057,
    },
    {
      x: 32,
      qx: 0.0006,
    },
    {
      x: 33,
      qx: 0.00062,
    },
    {
      x: 34,
      qx: 0.00064,
    },
    {
      x: 35,
      qx: 0.00067,
    },
    {
      x: 36,
      qx: 0.00074,
    },
    {
      x: 37,
      qx: 0.00084,
    },
    {
      x: 38,
      qx: 0.00093,
    },
    {
      x: 39,
      qx: 0.00104,
    },
    {
      x: 40,
      qx: 0.00114,
    },
    {
      x: 41,
      qx: 0.00126,
    },
    {
      x: 42,
      qx: 0.00141,
    },
    {
      x: 43,
      qx: 0.00158,
    },
    {
      x: 44,
      qx: 0.00175,
    },
    {
      x: 45,
      qx: 0.00193,
    },
    {
      x: 46,
      qx: 0.00214,
    },
    {
      x: 47,
      qx: 0.00239,
    },
    {
      x: 48,
      qx: 0.00268,
    },
    {
      x: 49,
      qx: 0.00299,
    },
    {
      x: 50,
      qx: 0.00334,
    },
    {
      x: 51,
      qx: 0.00374,
    },
    {
      x: 52,
      qx: 0.00422,
    },
    {
      x: 53,
      qx: 0.00479,
    },
    {
      x: 54,
      qx: 0.00542,
    },
    {
      x: 55,
      qx: 0.00607,
    },
    {
      x: 56,
      qx: 0.00669,
    },
    {
      x: 57,
      qx: 0.00725,
    },
    {
      x: 58,
      qx: 0.00776,
    },
    {
      x: 59,
      qx: 0.00826,
    },
    {
      x: 60,
      qx: 0.00877,
    },
    {
      x: 61,
      qx: 0.00936,
    },
    {
      x: 62,
      qx: 0.01004,
    },
    {
      x: 63,
      qx: 0.01104,
    },
    {
      x: 64,
      qx: 0.01214,
    },
    {
      x: 65,
      qx: 0.01334,
    },
    {
      x: 66,
      qx: 0.01466,
    },
    {
      x: 67,
      qx: 0.01612,
    },
    {
      x: 68,
      qx: 0.01771,
    },
    {
      x: 69,
      qx: 0.01947,
    },
    {
      x: 70,
      qx: 0.02121,
    },
    {
      x: 71,
      qx: 0.02319,
    },
    {
      x: 72,
      qx: 0.02539,
    },
    {
      x: 73,
      qx: 0.02778,
    },
    {
      x: 74,
      qx: 0.03042,
    },
    {
      x: 75,
      qx: 0.0333,
    },
    {
      x: 76,
      qx: 0.03646,
    },
    {
      x: 77,
      qx: 0.03991,
    },
    {
      x: 78,
      qx: 0.04372,
    },
    {
      x: 79,
      qx: 0.04789,
    },
    {
      x: 80,
      qx: 0.05247,
    },
    {
      x: 81,
      qx: 0.05877,
    },
    {
      x: 82,
      qx: 0.06579,
    },
    {
      x: 83,
      qx: 0.07284,
    },
    {
      x: 84,
      qx: 0.08061,
    },
    {
      x: 85,
      qx: 0.08925,
    },
    {
      x: 86,
      qx: 0.09713,
    },
    {
      x: 87,
      qx: 0.10893,
    },
    {
      x: 88,
      qx: 0.12131,
    },
    {
      x: 89,
      qx: 0.1345,
    },
    {
      x: 90,
      qx: 0.14645,
    },
    {
      x: 91,
      qx: 0.15243,
    },
    {
      x: 92,
      qx: 0.16454,
    },
    {
      x: 93,
      qx: 0.18235,
    },
    {
      x: 94,
      qx: 0.20488,
    },
    {
      x: 95,
      qx: 0.23305,
    },
    {
      x: 96,
      qx: 0.25962,
    },
    {
      x: 97,
      qx: 0.2872,
    },
    {
      x: 98,
      qx: 0.29173,
    },
    {
      x: 99,
      qx: 0.30759,
    },
    {
      x: 100,
      qx: 0.33241,
    },
    {
      x: 101,
      qx: 0.35918,
    },
    {
      x: 102,
      qx: 0.38871,
    },
    {
      x: 103,
      qx: 0.42124,
    },
    {
      x: 104,
      qx: 0.45705,
    },
    {
      x: 105,
      qx: 0.4958,
    },
    {
      x: 106,
      qx: 0.53553,
    },
    {
      x: 107,
      qx: 0.57626,
    },
    {
      x: 108,
      qx: 0.61725,
    },
    {
      x: 109,
      qx: 0.65996,
    },
    {
      x: 110,
      qx: 0.70366,
    },
    {
      x: 111,
      qx: 1,
    },
  ],
};
